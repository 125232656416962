import React, { useState, useLayoutEffect } from 'react';
import './App.scss';
import Home from '../Home';
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"

function App() {
  return (
    <div className="App">
      <div className="header">
        <div className="header-content">
          <div className="title">Here</div>
          <div className="title">For</div>
          <div className="title">Now</div>
        </div>
      </div>
      <Home />
    </div>
  );
}

export default App;
