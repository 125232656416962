import React, { useState, useLayoutEffect } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import Map from '../map';
import Counter from '../counter';
import database from './database.json'
import data from './map_data.json';
import { Container, Row, Col, Card, CardBody, CardImg, CardTitle, Button, CardSubtitle } from "shards-react";

function useWindowSize() {
  const [size, setSize] = useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  });
  useLayoutEffect(() => {
    function updateSize() {
      setSize({ 
        height: window.innerHeight,
        width: window.innerWidth
      });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function Home() {
  const {windowWidth} = useWindowSize(); 
  const [height, setHeight] = useState("400")
  const [mapOpened, setOpen] = useState(false)
  return (
    <>
      <Map 
        height={height}
        data={data}
        width={windowWidth ? windowWidth : window.innerWidth}
        onClick={() => {height === "400" ? setHeight("800"): setHeight("400"); setOpen(!mapOpened)}}
        opened={mapOpened}
      />
      <div className={"page page-" + height}>
        <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
          <Row>
            <Col>
              <Card style={{  overflow: "hidden" }}>
                <CardImg style={{ width: "100%", height: "auto" }} src="https://www.the-aviation-factory.com/media/destination-photo-big/146282-brussel-image-2.jpg" />
                <CardBody>
                  <CardTitle>Brussels</CardTitle>
                  <CardSubtitle>Belgium</CardSubtitle>
                  <p>This, was, a, mazing!</p>
                  <Button>Read more &rarr;</Button>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{  overflow: "hidden"  }}>
                <CardImg style={{ width: "100%", height: "auto" }} src="https://content.r9cdn.net/rimg/dimg/43/bb/dd7d13a8-city-32760-1682904a23f.jpg" />
                <CardBody>
                  <CardTitle>Stavanger</CardTitle>
                  <CardSubtitle>Norway</CardSubtitle>
                  <p>Another one down</p>
                  <Button>Read more &rarr;</Button>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{  overflow: "hidden"  }}>
                <CardImg style={{ width: "100%", height: "auto" }} src="https://www.ibo.org/contentassets/cd80d71ce577442293b991936f33f6ee/warsaw-header.jpg" />
                <CardBody>
                  <CardTitle>Warsaw</CardTitle>
                  <CardSubtitle>Poland</CardSubtitle>
                  <p>Come see us!</p>
                  <Button>Read more &rarr;</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* <Carousel /> */}
        <footer id="footer">
          &copy; 2020 Fauve and Greg 
        </footer>
      </div>
    </>
  );
}

export default Home;
