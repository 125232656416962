import React from 'react';

function Counter(props) {
  const countries = []
  props.data.forEach(travel => {
    if (countries.indexOf(travel.to.country) === -1) {
      countries.push(travel.to.country)
    }
  })
  return (
    <div className="counter">
      <div className="count">{countries.length}</div>
      <div>Countries</div>
      <div>Visited</div>
    </div>
  );
}

export default Counter;