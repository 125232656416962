import React, {Component} from 'react';
import ReactMapGL, {Layer, Source} from 'react-map-gl';
import arc from 'arc';
import GeoJSON from 'geojson'
import WebMercatorViewport from 'viewport-mercator-project';
import bbox from '@turf/bbox';

import 'mapbox-gl/dist/mapbox-gl.css';

const planeLinesDataLayer = {
  id: 'planeLinesData',
  type: 'line',
  paint: {
    'line-color': "#007bff",
    'line-width': 5,
  },
  layout: {
    "line-join": "round",
    "line-cap": "round"
    },
}

const planeDotsDataLayer = {
  id: 'planePointsData',
  type: 'circle',
  paint: {
    'circle-stroke-width': 4,
    'circle-stroke-color': "#007bff",
    'circle-color': "#FFF",
    'circle-radius': 4,
  },
}

const trainLinesDataLayer = {
  id: 'trainLinesData',
  type: 'line',
  paint: {
    'line-color': "#17c671",
    'line-width': 5,
  },
  layout: {
    "line-join": "round",
    "line-cap": "round"
    },
}

const trainDotsDataLayer = {
  id: 'trainPointsData',
  type: 'circle',
  paint: {
    'circle-stroke-width': 4,
    'circle-stroke-color': "#17c671",
    'circle-color': "#FFF",
    'circle-radius': 4,
  },
}

const Map = ({data, height, width, onClick, opened}) => (
  <>
    <div className={"map map-" + height}>
      <MapBase className="map-object" height={800} data={data} width={width}/>
      <div className={"resize-button-area-"+height}>
        <button className="resize-button" onClick={onClick}>
          <span className={"arrow-icon" + (opened ? " open" : "")}>
            <span className="left-bar"></span>
            <span className="right-bar"></span>
          </span>
        </button>
      </div>
    </div>
  </>
)

class MapBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewport: {
        width: props.width ? props.width : window.innerWidth,
        height: props.height ? props.height : window.innerHeight,
        latitude: 0,
        longitude: 0,
        zoom: 1.5
      },
      planeLinesData: null,
      planeDotsData: null,
      trainLinesData: null,
      trainDotsData: null,
      layers: null,
    }
  }

  componentDidMount() {
    this._loadData()
  }

  _makeLines() {
    const planeLines = []
    const trainLines = []
    this.props.data.forEach((trip, index) => {
      const routeName = "route_" + index
      const start = {x: trip.from.coordinates.longitude, y: trip.from.coordinates.latitude}
      const end = {x: trip.to.coordinates.longitude, y: trip.to.coordinates.latitude}
      const generator = new arc.GreatCircle(start, end, {name: routeName})
      const line = generator.Arc(40, {offset:10});
      
      if (trip.type === "plane") {
        planeLines.push(line.json())
      } else if (trip.type === "train") {
        trainLines.push(line.json())
      }
    })

    const planeLayers = {
      type: 'FeatureCollection',
      features: planeLines
    }

    const trainLayers = {
      type: 'FeatureCollection',
      features: trainLines
    }

    return {
      planeLinesData: planeLayers,
      trainLinesData: trainLayers
    }
  }

  _makeDots() {
    const planeCities = []
    const trainCities = []
    this.props.data.forEach((trip, index) => {
      if (trip.type === "plane") {
        planeCities.push({location: trip.from.name, lat: trip.from.coordinates.latitude, lon: trip.from.coordinates.longitude})
        if (index === this.props.data.length - 1) {
          planeCities.push({location: trip.to.name, lat: trip.to.coordinates.latitude, lon: trip.to.coordinates.longitude})
        }
      } else if (trip.type === "train") {
        trainCities.push({location: trip.from.name, lat: trip.from.coordinates.latitude, lon: trip.from.coordinates.longitude})
        if (index === this.props.data.length - 1) {
          trainCities.push({location: trip.to.name, lat: trip.to.coordinates.latitude, lon: trip.to.coordinates.longitude})
        }
      }
    })

    const planeLayers = GeoJSON.parse(planeCities, {Point: ['lat', 'lon']});
    const trainLayers = GeoJSON.parse(trainCities, {Point: ['lat', 'lon']});
    const allLayers = {type: "FeatureCollection", features: planeLayers.features.concat(trainLayers.features)}
    const {longitude, latitude, zoom} = this._getBounds(allLayers)
    console.log(zoom)

    return {
      planeDotsData: planeLayers,
      trainDotsData: trainLayers,
      layers: allLayers,
      viewport: {
        ...this.state.viewport,
        longitude,
        latitude,
        zoom
      }
    }
  }

  _loadData() {
    const lines = this._makeLines()
    const dots = this._makeDots()
    this.setState({
      ...lines,
      ...dots,
    })
  }

  _getBounds(layers = this.state.layers) {
    const [minLng, minLat, maxLng, maxLat] = bbox(layers)
    const viewport = new WebMercatorViewport({...this.state.viewport, height: this.props.height, width: this.props.width});
    return viewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
      padding: 100
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.height !== this.props.height || prevProps.width !== this.props.width) {
      const {longitude, latitude, zoom} = this._getBounds(this.state.layers)
      this.setState({
        viewport: {
          latitude: latitude,
          longitude: longitude,
          zoom: zoom,
          height: this.props.height ? this.props.height : window.innerHeight,
          width: this.props.width ? this.props.width : window.innerWidth
        }
      })
    }
  }

  render() {
    console.log(this.state.viewport.width)
    const {planeLinesData, planeDotsData, trainLinesData, trainDotsData} = this.state
    return (
      <ReactMapGL
        {...this.state.viewport}
        mapStyle="mapbox://styles/hamegreg/ck6pny83x2cst1jo59sunkyhm"
        onViewportChange={(viewport) => this.setState({viewport})}
      >
        {planeLinesData && (
          <Source id="my-planeLinesData" type="geojson" data={planeLinesData}>
            <Layer {...planeLinesDataLayer} />
          </Source>
        )}
        {trainLinesData && (
          <Source id="my-trainLinesData" type="geojson" data={trainLinesData}>
            <Layer {...trainLinesDataLayer} />
          </Source>
        )}
        {planeDotsData && (
          <Source id="my-planeDotsData" type="geojson" data={planeDotsData}>
            <Layer {...planeDotsDataLayer} />
          </Source>
        )}
        {trainDotsData && (
          <Source id="my-trainDotsData" type="geojson" data={trainDotsData}>
            <Layer {...trainDotsDataLayer} />
          </Source>
        )}
      </ReactMapGL>
    );
  }
}

export default Map